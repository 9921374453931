@import "../../assets/styles/utils";
.header {
    .logo {
        position: relative;
        z-index: 10;
        width: fit-content;
        position: absolute;
        @include res(top,30px, 5 / 30);
        @include res(left,3.125%);
        img {
            max-width: fit-content;
            @include res(width,100%,(sm:50%));
        }
    }
    .right {
        background: #C78F42;
        position: fixed;
        z-index: 1000;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s;
        cursor: pointer;
        @include res(top, 0,);
        @include res(right, 3.125%);
        @include res(width, 182px,(md:120px,xs:80px));
        @include res(height, 122px,(md:80px,xs:60px));
        &.hide{
          opacity: 0;
          visibility: hidden;
        }
        &.active{
            // @include res(background,transparent !important,(md:#C78F42));
        }
        &:not(.active){
          &:hover{
            background-color: $color-main-green !important;
          }
        }
        
        .cont{
            position: relative;
            z-index: 1;
            .text {
                width: 100%;
                text-align: center;
                @include res(font-size, 14px, 12 / 14);
                color: #fff;
                margin-top: 10px;
            }
            #menu {
                transition: all 0.3s;
                margin: 0 auto;
                div {
                    transition-duration: .3s;
                    &:after {
                        transition-duration: .3s;
                    }
                }
                @include res(width, 38px, (md:28px));
                &:hover {
                        div {
                            @include res(width, 38px, (md:28px));
                            &:after {
                                @include res(width, 38px, (md:28px));
                            }
                        }
                }
        
                div {
                    @include res(width, 28px, (md:18px));
                    background: #fff;
                    transition: all 0.3s;
        
                    &:before {
                        transition: all .3s;
                        @include res(width, 38px, (md:28px));
                        background: #fff;
                    }
        
                    &:after {
                        transition: all 0.3s;
                        @include res(width, 28px, (md:18px));
                        background: #fff;
                    }
                }
        
                view {
                    @include res(width, 38px, (md:28px));
                    background: #fff;
        
                    &:after,
                    &:before {
                        @include res(width, 38px, (md:28px));
                        background: #fff;
                    }
                }
        
                &.active {
                    div {
                        @include res(width, 38px, (md:28px));
        
                        &:after,
                        &:before {
                            @include res(width, 38px, (md:28px));
                        }
                    }
                }
            }
        }
        
    }  
    .nav-bg{
        height: 0;
        overflow: hidden;
        z-index: 10;
        position: fixed;
        transition: height .5s cubic-bezier(0, 0, 0.22, 0.85);
        @include res(display,null,(sm:none));
        @include res(top, 0,);
        @include res(right, 3.125%);
        img{
            @include res(height, 664px,(md:438px,xs:292px));
            @include res(width, 182px,(md:120px,xs:80px));
        }
    } 
    .language{
        position: absolute;
        text-transform: uppercase;
        color: #fff;
        z-index: 10;
        @include res(font-size, 20px, 16 / 20);
        @include res(right,15%, 30 / 15);
        @include res(top, 45px, 20 / 45);
        &:hover{
            .iconfont{
                transform: rotate(180deg);
            }
            .sub-language{
                opacity: 1;
                visibility: visible;
                pointer-events:all;
            }
        }
        .iconfont{
            transition: all .3s;
            display: inline-block;
            transform-origin: center;
            @include res(font-size,18px, 16 / 18);
            @include res(margin-left,10px, 8 / 10);
        }
        .sub-language{
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            pointer-events: none;
            position: absolute;
            top: 100%;
            left: 0;
            a{
                display: block;
                @include res(padding-top,10px, 5 / 10);
                @include res(padding-bottom,10px, 5 / 10);
            }
        }
    }
}

.header-nav {
    overflow-y: auto;
    display: flex;
    position: fixed;
    left: 100%;
    top: 0;
    z-index: -1;
    transform: translateX(-100%);
    opacity: 0;
    width: 100%;
    height: 100%;
    background: #157574;
    transition: all .5s;
    &.active {
        opacity: 1;
        z-index: 300;
    }
    .logo {
        @include res(margin-top, 30px, 5 / 30);
        position: absolute;
        @include res(left, 3.125%, );
        z-index: 10;
        width: fit-content;
        img {
            max-width: fit-content;
            @include res(width, 100%, (sm:50%));
        }
    }
    .pic {
        @include res(display,block,(sm:none));
        width: 32.3%;
        transform: translateX(-100%);
        transition: all .5s;
        position: relative;
        .img {
            width: 100%;
            height: 100%;
        }
    }
    .right {
        @include res(padding-top,150px,(mmd:100px));
        box-sizing: border-box;
        @include res(width, 67.7%, (sm:100%));
        padding-left: 4.9%;
        padding-right: 6.77%;
        position: relative;
        transition: all .5s;
        opacity: 0;
        .nav {
            flex-wrap: wrap;
            display: flex;
            justify-content: space-between;
            transition: all .5s;
            transform: translateY(20px);
            dl {
                @include res(width,fit-content,(xs:33%));
                @include res(margin-bottom, 0,(xs:20px));
                dt {
                    color: #F4D296;
                    font-weight: bold;
                    @include res(font-size,20px, 16 / 20);
                    @include res(margin-bottom,25px, 15 / 25);
                }
                dd {
                    color: #FFFFFF;
                    transition: all .3s;
                    position: relative;
                    @include res(font-size, 16px, 14 / 16);
                    @include res(margin-bottom, 15px, 10 / 15);
                    a{
                        display: inline-block;
                        position: relative;
                        @include res(padding-top, 5px);
                        @include res(padding-bottom, 5px);
                        &::after,&::before{
                            content: '';
                            display: block;
                            width: 0;
                            height: 1px;
                            background-color: #F4D296;
                            transition: all .3s;
                            position: absolute;
                            left: 0;
                        }
                        &::before{
                            top: 0;
                        }
                        &::after{
                            bottom: 0;
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        a{
                            &::after,&::before{
                                width: 100%;
                            }
                        }
                        color: #F4D296;
                    }
                }
            }
        }
        .text {
            @include res(display,block,(xs:none));
            border-top: 1px solid #94AA8B;
            border-bottom: 1px solid #94AA8B;
            @include res(margin-top,55px);
            img {
                @include res(padding-top,38px,15 / 38);
                @include res(padding-bottom, 38px, 15 / 38);
                display: block;
                width: 100%;
            }
        }
        .menu-bg {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            img {
                display: block;
                width: 100%;
            }
        }
        .contact-us {
            float: right;
            width: fit-content;
            position: relative;
            z-index: 10;
            @include res(margin-top,65px,(mmd:50px));
            &:hover{
                &:before,&:after{
                    background: #fff;
                }
                a{
                    color: #fff;
                }
            }
            &:before {
                content: '';
                width: 100%;
                height: 1px;
                background: #F4D296;
                position: absolute;
                bottom: -5px;
                left: 0;
                transition: all .3s;
            }
            &:after {
                content: '';
                width: 100%;
                height: 1px;
                background: #F4D296;
                position: absolute;
                top: -5px;
                left: 0;
                transition: all .3s;
            }
            a {
                font-weight: bold;
                display: block;
                width: fit-content;
                color: #F4D296;
                position: relative;
                padding: 5px 0;
                transition: all .3s;
                @include res(font-size,20px,18 / 20);
            }
        }
    }
}