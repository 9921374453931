@font-face {
  font-family: "iconfont"; /* Project id 3625447 */
  src: url('iconfont.woff2?t=1663228380064') format('woff2'),
       url('iconfont.woff?t=1663228380064') format('woff'),
       url('iconfont.ttf?t=1663228380064') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dingwei:before {
  content: "\e60d";
}

.icon-guanbi:before {
  content: "\e625";
}

.icon-rili:before {
  content: "\e789";
}

.icon-zengjiahang:before {
  content: "\e667";
}

.icon-tijiao:before {
  content: "\e620";
}

.icon-iconfontdaoshi:before {
  content: "\e609";
}

.icon-exe-lecturer-primary:before {
  content: "\e910";
}

.icon-xingtuxuetang-neixunshi-:before {
  content: "\e608";
}

.icon-kecheng-:before {
  content: "\e61f";
}

.icon-zhaogaoxiao:before {
  content: "\e65e";
}

.icon-xuexipingtai:before {
  content: "\e69e";
}

.icon-gongzuohuanjing:before {
  content: "\e618";
}

.icon-huodongyingxiao:before {
  content: "\e687";
}

.icon-XX_001:before {
  content: "\e6b0";
}

.icon-bofang:before {
  content: "\e624";
}

.icon-jiantou_xiangyou:before {
  content: "\eb08";
}

.icon-caozuo-wailian:before {
  content: "\e711";
}

.icon-home:before {
  content: "\e6b8";
}

.icon-jiantou_you:before {
  content: "\e893";
}

.icon-jiantou_xia:before {
  content: "\e894";
}

.icon-jiantou_zuo:before {
  content: "\e895";
}

.icon-jiantou_shang:before {
  content: "\e892";
}

.icon-search:before {
  content: "\e6ac";
}

.icon-youjiantou:before {
  content: "\e678";
}

.icon-shubiao:before {
  content: "\e662";
}

