@import "./utils";
@import "~jason-css/src/responsive/index";
@import "../fonts/iconfont.css";

@font-face {
  font-family: DIN-Medium;
  src: url('../fonts/DIN-Medium.eot');
  src: url('../fonts/DIN-Medium.woff') format('woff'),
    url('../fonts/DIN-Medium.ttf') format('truetype'),
    url('../fonts/DIN-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

*{
    // touch-action: pan-y;

    /* 启用单指垂直平移手势 */
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline:none;
  //文字中空描边
  // color: transparent;
  // -webkit-text-stroke: 1px #fff;
  // -webkit-font-smoothing: antialiased;
}

body {
  font-family: DIN-Medium,'微软雅黑';
  overflow-x: hidden;
  line-height: 1.2;
  position: relative;
  color: $color-text;
  background-color: #fff;
  @include res(font-size, $text-size-base, $text-size-ratio);
}
.comp-root{
  overflow: hidden;
}

//清除浮动
.clear {
  zoom: 1;

  &::after {
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
    clear: both;
  }
}


//主体内容部分宽度
.container {
  box-sizing: content-box;
  width: 100%;
  max-width: 1400px;
  @include res(padding-left, $container-gutter-base, $container-gutter-ratio);
  @include res(padding-right, $container-gutter-base, $container-gutter-ratio);

  &.container-1200 {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1600px) {
  .container {
    box-sizing: border-box;
    max-width: 1330px;
  }
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    max-width: 960px;
  }
}
@media screen and (max-width: 768px) {
  .container {
    max-width: 700px;
  }
}
.newsicon {
  display: block;
  width: 75px;
  height: 26px;
  background-color: #157574;
  line-height: 26px;
  text-align: center;
  font-size: 14px;
  color: #fff0d9;
}

@keyframes BaseButton {
  0% {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53)
  }

  50% {
    opacity: 0;
    transform: translateY(15px);
    transition-timing-function: cubic-bezier(.25, .46, .45, .94)
  }

  50.001% {
    transform: translateY(-15px)
  }

  to {
    opacity: 1;
    transform: translateY(0)
  }
}

@keyframes rain {
  0% {
    opacity: 1
  }

  25% {
    opacity: 0
  }

  50% {
    opacity: 1
  }

  75% {
    opacity: 0
  }

  to {
    opacity: 1
  }
}

// 列表中图片有占位图片的
.haszw-img{
  position: relative;
  overflow: hidden;
  img{
    display: block;
    width: 100%;
    height: auto;
    opacity: 0;
  }
  b,a{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center;
    background-size: cover;
    transition: all .3s;
  }
}

// 内页banner
.ny-banner{
  position: relative;
  @include res(padding-top,340px, 120 / 340);
  @include res(padding-bottom,30px, 20 / 30);
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    b{
      display: block;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;
    }
  }
  .container{
    position: relative;
    z-index: 2;
    color: #fff;
    .banner-nav{
      position: relative;
      @include res(text-align,null,(md:center));
      @include res(margin-bottom,90px, 40 / 90);
      &::after,&::before{
        content: '';
        width: 100%;
        height: 1px;
        background-color: rgba(255,255,255,.5);
        position: absolute;
        left: 0;
        transition: all 1s cubic-bezier(0, 0, 0.04, 0.98);
        transform: scaleX(0);
        transform-origin: left;
        @include res(display,block,(md:none));
      }
      &::before{
        top: 0;
      }
      &::after{
        bottom: 0;
      }
      &.active{
        &::after,&::before{
          transform: scaleX(1);
        }
        .cont{
          .nav-title{
            border-top: 2px solid rgba(255,255,255,.9);
            border-bottom: 2px solid rgba(255,255,255,.9);
          }
          .sub-nav{
            a{
              opacity: 1;
              &:nth-child(1){
                transition-delay: .1s;
              }
              &:nth-child(2){
                transition-delay: .2s;
              }
              &:nth-child(3){
                transition-delay: .3s;
              }
              &:nth-child(4){
                transition-delay: .4s;
              }
              &:nth-child(5){
                transition-delay: .5s;
              }
              &:nth-child(6){
                transition-delay: .6s;
              }
              &:nth-child(7){
                transition-delay: .7s;
              }
            }
          }
        }
      }
      .cont{
        position: relative;
        display: inline-block;
        .nav-title{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border-top: 2px solid rgba(255,255,255,.6);
          border-bottom: 2px solid rgba(255,255,255,.6);
          cursor: pointer;
          transition: all .3s;
          @include res(padding-top,20px, 10 / 20);
          @include res(padding-bottom,20px, 10 / 20);
          @include res(padding-left,10px, 10 / 10);
          @include res(padding-right,10px, 10 / 10);
          h1{
            font-weight: bold;
            @include res(font-size,46px, 22 / 46);
          }
          .icon{
            border-radius: 50%;
            background-color: $color-main-brown;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            @include res(margin-left,25px, 15 / 25);
            @include res(width,50px, 40 / 50);
            @include res(height,50px, 40 / 50);
            &:hover{
              &::after{
                transform: scaleX(1);
              }
            }
            &::after,&::before{
              content: '';
              display: block;
              height: 2px;
              background-color: #fff;
              transition: all .3s;
              margin: 3px 0; 
              @include res(width,20px);
            }
            &::after{
              transform-origin: left;
              transform: scaleX(.7);
            }
          }
        }
        .sub-nav{
          position: absolute;
          white-space: nowrap;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;
          box-sizing: border-box;
          z-index: 4;
          @include res(display,flex,(md:none));
          @include res(font-size,24px, 18 / 24);
          @include res(left,100%,(md:0));
          @include res(top,50%,(md:100%));
          @include res(transform,translateY(-50%),(md:translateY(0)));
          @include res(background-color,transparent,(md:rgba(199,143,66,1)));
          @include res(width,null,(md:100%));
          @include res(padding-left,null,(md:30px));
          a{
            display: block;
            transition: all .5s cubic-bezier(0.7, 0.01, 0.22, 1.01);
            @include res(opacity,0,(md:1));
            @include res(margin-left,35px,(md:0));
            @include res(padding-top,null,(md:10px));
            @include res(padding-bottom,null,(md:10px));
            span{
              display: flex;
              align-items: center;
              justify-content: flex-start;
              transition: all .3s;
              &::before{
                content: '·';
                @include res(margin-right,15px);
                @include res(font-size, 30px, 20 / 30);
              }
            }
            &:hover{
              span{
                @include res(color,$color-main-brown,(md:#fff));
              }
            }
            &:nth-child(1){
              transition-delay: .7s;
            }
            &:nth-child(2){
              transition-delay: .6s;
            }
            &:nth-child(3){
              transition-delay: .5s;
            }
            &:nth-child(4){
              transition-delay: .4s;
            }
            &:nth-child(5){
              transition-delay: .3s;
            }
            &:nth-child(6){
              transition-delay: .2s;
            }
            &:nth-child(7){
              transition-delay: .1s;
            }
          }
        }
      }
      
    }
    // .banner-crumbs{
    //   display: flex;
    //   align-items: center;
    //   justify-content: flex-end;
    //   span{
    //     margin: 0 5px;
    //   }
    //   a{
    //     display: block;
    //     transition: all .3s;
    //     &:hover{
    //       color: $color-main-brown;
    //     }
    //     &:first-child{
    //       padding-left: 25px;
    //       position: relative;
    //       &::after{
    //         content: '\e6b8';
    //         font-family: 'iconfont';
    //         position: absolute;
    //         left: 0;
    //         top: 50%;
    //         transform: translateY(-50%);
    //         @include res(font-size,18px, 16 / 18);
    //       }
    //     }
    //   }
    // }
  }
  #md{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0;
    left: 0;
  }
}
.banner-crumbs{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span{
    margin: 0 5px;
  }
  a{
    display: block;
    transition: all .3s;
    &:hover{
      color: $color-main-brown;
    }
    &:first-child{
      padding-left: 25px;
      position: relative;
      &::after{
        content: '\e6b8';
        font-family: 'iconfont';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include res(font-size,18px, 16 / 18);
      }
    }
  }
}
.line-h{
  @include res(line-height,2, 1.6 / 2);
}

// 内页公共title
.common-title{
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #b8d5d5;
  flex-wrap: wrap;
  @include res(margin-top,100px, 15 / 100);
  @include res(margin-bottom,100px, 10 / 100);
  @include res(display,flex);
  h1{
    @include res(padding-top, 10px);
    @include res(padding-bottom, 18px, 10 / 18);
    span{
      display: inline-block;
      color: $color-main-green;
      font-weight: bold;
      background: url(../../assets/images/title_bg.jpg) no-repeat left center;
      background-size: auto 100%;
      line-height: 1;
      @include res(padding-left, 25px, 10 / 25);
      @include res(font-size,30px, 20 / 30);
    }
  }
  .sub-nav{
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    white-space: nowrap;
    @include res(overflow-x,null,(md:auto));
    @include res(overflow-y,null,(md:hidden));
    a{
      color: $color-main-green;
      padding-left: 5px;
      padding-right: 5px;
      display: block;
      transition: all .3s;
      position: relative;
      @include res(padding-bottom,15px, 10 / 15);
      @include res(padding-top,15px, 10 / 15);
      @include res(font-size,18px, 16 / 18);
      @include res(margin-left,35px, 15 / 35);
      &:hover{
        color: $color-main-brown;
      }
      &.active{
        &::after{
          transform: scaleX(1);
        }
      }
      &::after{
        content: '';
        display: block;
        width: 100%;
        background-color: $color-main-brown;
        position: absolute;
        bottom: -1px;
        left: 0;
        transform: scaleX(0);
        transition: all .3s;
        transform-origin: left;
        @include res(height,3px);
      }
    }
  }
  .right-search{
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @include res(width,325px,(xs:100%));
    @include res(padding-bottom,15px, 10 / 15);
    @include res(padding-top,null,(xs:10px));
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $color-main-green;
      position: absolute;
      right: 0;
      bottom: -1px;
    }
    input{
      border: none;
      background-color: transparent;
      flex-grow: 1;
      @include res(font-size,18px, 16 / 18);
    }
    button{
      border: none;
      background-color: transparent;
      cursor: pointer;
      @include res(width,50px);
      .iconfont{
        @include res(font-size,22px, 16 / 22);
      }
    }
  }
}

.common-more{
  border-bottom: 1px solid $color-main-green;
  color: $color-main-green;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // @include res(width,165px);
  @include res(padding-bottom,10px, 5 / 10);
  &:hover{
    span{
      transform: translateX(5px);
    }
    .iconfont{
      transform: translateX(-5px);
    }
  }
  span{
    transition: all .3s;
    @include res(font-size,14px, 12 / 14);
    @include res(margin-right,90px, 50 / 90);
  }
  .iconfont{
    font-weight: bold;
    font-size: 14px;
    transition: all .3s;
  }
}

.page{
  display: flex;
  align-items: center;
  justify-content: center;
  @include res(margin-top,60px, 20 / 60);
  a{
    border-radius: 50%;
    background-color: #c9bc9c;
    color: $color-main-green;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    @include res(width,56px, 36 / 56);
    @include res(height,56px, 36 / 56);
    &:not(:first-child){
      @include res(margin-left,18px, 5 / 18);
    }
    &:hover,&.active{
      background-color: $color-main-green;
      color: #fff;
    }
  }
}

.mt-150{
  @include res(margin-bottom, 150px, 50 / 150);
}


//期刊公共部分
.journal-header{
  align-items: center;
  justify-content: space-between;
  @include res(display,flex);
  @include res(padding-left, 60px, 10 / 60);
  @include res(padding-right, 110px, 10 / 110);
  @include res(padding-top, 20px, 10 / 20);
  @include res(padding-bottom, 20px, 10 / 20);
  .left-logo{
    img{
      display: block;
      height: auto;
      @include res(width, 154px, 60 / 154);
    }
  }
  .right{
    a{
      display: flex;
      align-items: center;
      color: #000;
      transition: all .3s;
      @include res(font-size, 20px, 16 / 20);
      &:hover{
        color: $color-main-green;
      }
      .iconfont{
        color: $color-main-green;
        @include res(margin-right,18px, 10 / 18);
        @include res(font-size, 24px, 16 / 24)
      }
    }
  }
}

.journal-nav{
  background: url(../images/journal_bg1.jpg) no-repeat bottom center;
  background-size: cover;
  color: #fff;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  @include res(display,flex,(xs:block));
  @include res(padding-left, 120px, 10 / 120);
  @include res(padding-right, 85px, 10 / 85);
  @include res(padding-top, 30px, 10 / 30);
  @include res(padding-bottom, 30px, 10 / 30);
  .left{
    border-left: solid #fff;
    line-height: 1.6;
    @include res(border-left-width, 6px, 3 / 6);
    @include res(margin-bottom,null,(xs:20px,xxs:10px));
    h1{
      font-weight: bold;
      @include res(font-size, 42px, 20 / 42);
    }
    .date{
      @include res(padding-left, 20px, 10 / 20);
      @include res(font-size, 24px, 18 / 24);
    }
  }
  .right{
    @include res(font-size, 24px, 18 / 24);
    a{
      transition: all .3s;
      &:not(:last-child){
        @include res(margin-right,50px, 15 / 50);
      }
      &:hover{
        color: $color-main-green;
      }
    }
  }
}

.journal-ny-common{
  align-items: stretch;
  justify-content: space-between;
  @include res(height, 80vh,(sm:auto));
  @include res(display,flex,(sm:block));
  .left-img{
    flex-shrink: 0;
    background: #f6f1eb;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include res(height,100%,(sm:600px,xs:400px));
    @include res(width,50%,(sm:100%));
    img{
      display: block;
      max-height: 90%;
      max-width: 90%;
    }
  }
  .right-tab{
    flex-shrink: 0;
    box-sizing: border-box;
    flex-direction: column;
    @include res(padding-top,100px, 30 / 100);
    @include res(padding-left,160px, 10 / 160);
    @include res(padding-right,70px, 10 / 70);
    @include res(padding-bottom,100px, 30 / 100);
    @include res(width,50%,(sm:100%));
    .tab{
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      position: relative;
      flex-shrink: 0;
      @include res(margin-bottom,70px, 20 / 70);
      &::after{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e2e2e2;
        position: absolute;
        bottom: 1px;
        left: 0;
        z-index: -1;
      }
      a{
        padding: 0 10px;
        color: $color-main-green;
        border-bottom: 3px solid transparent;
        transition: all .3s;
        font-weight: bold;
        cursor: pointer;
        @include res(font-size,24px, 16 / 24);
        @include res(padding-bottom,10px);
        &:not(:last-child){
          @include res(margin-right, 20px, 10 / 20);
        }
        &:hover{
          color: $color-main-brown;
        }
        &.active{
          color: $color-main-brown;
          border-bottom: 3px solid $color-main-brown;
        }
      }
    }
    .cont{
      overflow-y: auto;
      padding-right: 10px;
      @include res(height,55vh,(sm:600px,xs:360px));
      /*滚动条样式*/
      &::-webkit-scrollbar {
        width: 4px;    
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
        background: rgba($color-main-brown, .6);
        width: 4px;   
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px rgba(0,0,0,.1);
        border-radius: 0;
        background: rgba(0,0,0,.1);
      }
      // .swiper{
      //   .swiper-slide{
      //     height: auto;
      //     box-sizing: border-box;
      //     position: relative;
      //   }
      // }
    }
    &.catalogue{
      .cont{
        .title{
          font-weight: bold;
          color: #333;
          @include res(font-size, 22px, 18 / 22);
          @include res(margin-bottom,35px, 15 / 35);
        }
        .list{
          a{
            display: flex;
            align-items: flex-start;
            transition: all .3s;
            @include res(font-size, 24px, 16 / 24);
            @include res(margin-bottom,30px, 10 / 30);
            &::before{
              content: '';
              display: block;
              background-color: $color-text;
              border-radius: 50%;
              @include res(width,6px);
              @include res(height,6px);
              @include res(margin-top, 12px, 5 / 12);
              @include res(margin-right, 10px);
            }
            &:hover{
              color: $color-main-brown;
              &::before{
                background-color: $color-main-brown;
              }
            }
          }
        }
      }
    }
    &.article{
      @include res(padding-top,50px, 30 / 50);
      @include res(padding-left,70px, 10 / 70);
      .cont{
        .title{
          background-color: $color-main-brown;
          color: #fff;
          font-weight: bold;
          position: relative;
          @include res(font-size,24px, 18 / 24);
          @include res(padding-left,35px, 10 / 35);
          @include res(padding-right,35px, 10 / 35);
          @include res(padding-top,10px, 5 / 10);
          @include res(padding-bottom,10px, 5 / 10);
          @include res(margin-bottom,40px, 20 / 40);
          &::after{
            content: '';
            width: 11px;
            height: 100%;
            display: block;
            background-color: $color-main-brown;
            position: absolute;
            top: 0;
            left: calc(100% - 1px);
          }
        }
        .desc{
          color: #333;
          @include res(padding-left, 40px, 10 / 40);
          @include res(padding-right, 40px, 0 / 40);
          @include res(line-height, 2 , 1.6 / 2);
          p{
            text-indent: 2rem;
          }
        }
      }
    }
  }
}